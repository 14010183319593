// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title_2{
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 25px;
}
.ContainerButton_2{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}
.textStyle-2{
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
}

.LockPassword{
    position: relative;
    bottom: 33px;
    left: 15px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Auth/Register/Register.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;AACxB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,eAAe;AACnB","sourcesContent":[".title_2{\n    margin-top: 20px;\n    margin-bottom: 50px;\n    font-size: 25px;\n}\n.ContainerButton_2{\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding-top: 20px;\n    padding-bottom: 20px;\n}\n.textStyle-2{\n    cursor: pointer;\n    margin-top: 20px;\n    margin-bottom: 20px;\n}\n\n.LockPassword{\n    position: relative;\n    bottom: 33px;\n    left: 15px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
