import axios from "axios";
import { environment } from "../../environments/environments";


const RegisterUser=async (body)=>{
    
    const path  = environment.api + environment.register;

    return await axios.post(path,body);

}


const LoginUser=async (body)=>{
    
    const path  = environment.api + environment.login;
    return await axios.post(path,body);

}


const GetUser=async ()=>{
    
    const path  = environment.api + environment.getUser;

    return await axios.get(path);
}

const GetSpecificUser=async(idUser)=>{
    const path =  environment.api + environment.getSpecificUser+idUser+'/';
    return await axios.get(path);
}

const GetUserInfo=async(token)=>{
    const path =  environment.api+ environment.userInfo;
    let headers = {
        Authorization:'Token '+token,
    }
    return await axios.get(path,headers);

}

const UpdateUser=async (body,id)=>{
    

    const path  = environment.api + environment.editUser+id+'/';
    return await axios.put(path,body);
    

}

const generateCertificate=async(id)=>{
    const path =  environment.api + environment.generateCertificate+id+'/';
    return await axios.get(path, { responseType: 'blob' });
}



export {generateCertificate,RegisterUser,LoginUser,GetUser,UpdateUser,GetSpecificUser,GetUserInfo}