// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Body{
  width: 100vw;
  min-width: 100vw;
  min-height: 100vh;
  height: 100vh;
}

.flex-column{
  display: flex;
  flex-direction: column;
}
.align-center{
  align-items: center;
  justify-content: center;
}

.flex-row{
  display: flex;
  flex-direction: column;
}
.bg-purple{
  background-color: #662d910d  !important;
}
.bg-purple_light{
  background-color: #c32ee80f  !important;
}

.color-purple-light{
  color: #E59AB5;
}

.color-purple{
  color:#5F3473;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,uCAAuC;AACzC;AACA;EACE,uCAAuC;AACzC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".Body{\n  width: 100vw;\n  min-width: 100vw;\n  min-height: 100vh;\n  height: 100vh;\n}\n\n.flex-column{\n  display: flex;\n  flex-direction: column;\n}\n.align-center{\n  align-items: center;\n  justify-content: center;\n}\n\n.flex-row{\n  display: flex;\n  flex-direction: column;\n}\n.bg-purple{\n  background-color: #662d910d  !important;\n}\n.bg-purple_light{\n  background-color: #c32ee80f  !important;\n}\n\n.color-purple-light{\n  color: #E59AB5;\n}\n\n.color-purple{\n  color:#5F3473;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
