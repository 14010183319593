// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heightImportant{
    height: 400px !important;
}

/* ---------------------------------------------------
    PHOTO IMG
  ----------------------------------------------------- */

  .ContainerForm{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .ContainerPhoto{
    width: 100px;
    height: 100px;
    background-color: rgba(130, 130, 130, 0.266);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
  }
/*   
  .file-input-photo- {
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 1;
  } */
  
  .file-input-photo-:focus {
    outline: none;
  }`, "",{"version":3,"sources":["webpack://./src/Lobby/Moduls/Admin/StoriesModuls/StoriesModuls.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;;yDAEyD;;EAEvD;IACE,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,YAAY;IACZ,aAAa;IACb,4CAA4C;IAC5C,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,oBAAoB;EACtB;AACF;;;;;;;KAOK;;EAEH;IACE,aAAa;EACf","sourcesContent":[".heightImportant{\n    height: 400px !important;\n}\n\n/* ---------------------------------------------------\n    PHOTO IMG\n  ----------------------------------------------------- */\n\n  .ContainerForm{\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 20px;\n  }\n  .ContainerPhoto{\n    width: 100px;\n    height: 100px;\n    background-color: rgba(130, 130, 130, 0.266);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 100px;\n  }\n/*   \n  .file-input-photo- {\n    height: 100%;\n    width: 100%;\n    cursor: pointer;\n    opacity: 0;\n    z-index: 1;\n  } */\n  \n  .file-input-photo-:focus {\n    outline: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
