// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textStyle-3{
    text-align: center;
    max-width: 320px;
}`, "",{"version":3,"sources":["webpack://./src/Auth/Recovery/Recovery.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".textStyle-3{\n    text-align: center;\n    max-width: 320px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
