// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContainerAuth{
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
}
.DecorationContainer{
    width: 30%;
    max-width: 320px;
    min-height: 100vh;
    box-shadow: var(--box-shadow-4-);
}
.LoginContainer{
    min-width: 75%;
    background-color: white;
    border-top-left-radius:10px;
    border-bottom-left-radius: 10px;
    box-shadow: var(--box-shadow-4-);
}

.BackContainer{
    position: absolute;
    background-color: white;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 1px solid #000;
    left: 60px;
    top:150px
}

/*
responsive
*/

@media (min-width: 0px) and (max-width: 550px) {
    .DecorationContainer{
        display: none;
    }
    .LoginContainer{
        width: 100%;
        background-color: white;
        border-top-left-radius:10px;
        border-bottom-left-radius: 10px;
        box-shadow: var(--box-shadow-4-);
    }


}`, "",{"version":3,"sources":["webpack://./src/Auth/Auth.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,gBAAgB;IAChB,iBAAiB;IACjB,gCAAgC;AACpC;AACA;IACI,cAAc;IACd,uBAAuB;IACvB,2BAA2B;IAC3B,+BAA+B;IAC/B,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,UAAU;IACV;AACJ;;AAEA;;CAEC;;AAED;IACI;QACI,aAAa;IACjB;IACA;QACI,WAAW;QACX,uBAAuB;QACvB,2BAA2B;QAC3B,+BAA+B;QAC/B,gCAAgC;IACpC;;;AAGJ","sourcesContent":[".ContainerAuth{\n    min-width: 100vw;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: row;\n}\n.DecorationContainer{\n    width: 30%;\n    max-width: 320px;\n    min-height: 100vh;\n    box-shadow: var(--box-shadow-4-);\n}\n.LoginContainer{\n    min-width: 75%;\n    background-color: white;\n    border-top-left-radius:10px;\n    border-bottom-left-radius: 10px;\n    box-shadow: var(--box-shadow-4-);\n}\n\n.BackContainer{\n    position: absolute;\n    background-color: white;\n    width: 40px;\n    height: 40px;\n    border-radius: 40px;\n    border: 1px solid #000;\n    left: 60px;\n    top:150px\n}\n\n/*\nresponsive\n*/\n\n@media (min-width: 0px) and (max-width: 550px) {\n    .DecorationContainer{\n        display: none;\n    }\n    .LoginContainer{\n        width: 100%;\n        background-color: white;\n        border-top-left-radius:10px;\n        border-bottom-left-radius: 10px;\n        box-shadow: var(--box-shadow-4-);\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
