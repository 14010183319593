// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormContainer{
    width: 100%;
}

.TableUsersContainer{
    width: 100%;
    min-height: 500px;
    border-radius: 20px;
    background-color: #fff;
    padding: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Lobby/Moduls/Admin/Users/Users.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,sBAAsB;IACtB,aAAa;AACjB","sourcesContent":[".FormContainer{\n    width: 100%;\n}\n\n.TableUsersContainer{\n    width: 100%;\n    min-height: 500px;\n    border-radius: 20px;\n    background-color: #fff;\n    padding: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
